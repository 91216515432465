import React from 'react'
import logo from "../Images/Group 2070.svg"
import facebook from "../Images/Vector.svg"
import twitter from "../Images/Group 86.svg"
import linkned from "../Images/Group 87.svg"
import instagram from "../Images/Group 88.svg"
import tiktok from "../Images/logo-tiktok-svgrepo-com.svg"

const FooteR = () => {
  return (
    <div className='flex justify-center items-center w-full px-[6%] bg-[#872794] pt-[4%] pb-[5%] mt-[7%] max-lg:py-[25%] max-sm:px-0'>
        <div className='flex flex-col justify-evenly align-middle w-[50%] max-sm:w-full'>
            <div className='w-full'>
                <img className='mx-auto block ' src={logo} alt=''></img>
            </div>
            <div className='mt-[7%] max-lg:mt-[10%] text-center max-sm:w-full'>
                <h4 className='text-white max-lg:hidden' id='font-livvic'>
                    simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text 
                </h4>
                <h4 className='text-white lg:hidden' id='font-livvic'>
                    simply dummy text of the printing<br></br> and typesetting industry. Lorem<br></br> Ipsum has been the industry's<br></br> standard dummy text 
                </h4>
            </div>
            <div className='mt-[6%] w-full max-lg:mt-[10%] max-lg:w-full'>
                <ul className='w-full flex justify-between text-white text-sm max-lg:hidden' id='font-roboto'>
                    <li><a target='_blank' rel="noreferrer" href='/'>Help</a></li>
                    <li><a target='_blank' rel="noreferrer" href='/'>Home</a></li>
                    <li><a target='_blank' rel="noreferrer" href='/'>Terms of Service</a></li>
                    <li><a target='_blank' rel="noreferrer" href='/'>User Policy</a></li>
                    <li><a target='_blank' rel="noreferrer" href='/'>Privacy</a></li> 
                </ul>
                {/* mobile */}
                <ul className='lg:hidden w-full grid place-items-center'>
                    <div className='w-[90%] flex justify-center text-white text-sm'>
                        <li className=''><a target='_blank' rel="noreferrer" href='/'>Help</a></li>
                        <li className='ml-3'><a target='_blank' rel="noreferrer" href='/'>Home</a></li>
                        <li className='ml-3'><a target='_blank' rel="noreferrer" href='/'>Terms of Service</a></li>
                    </div>
                    <div className='w-[50%]] mt-4 flex justify-center text-white text-sm'>
                        <li className=''><a target='_blank' rel="noreferrer" href='/'>User Policy</a></li>
                        <li className='ml-2'><a target='_blank' rel="noreferrer" href='/'>Privacy</a></li>
                    </div>
                </ul>
            </div>
            <div className='w-full mt-[6%] max-lg:w-full max-lg:mt-[10%] flex justify-center'>
                <ul className='w-[65%]  flex justify-between text-sm items-center'>
                    <li><a target='_blank' rel="noreferrer" href='/'><img src={facebook} alt=''></img></a></li>
                    <li><a target='_blank' rel="noreferrer" href='https://twitter.com/shophoarder'><img src={twitter} alt=''></img></a></li>
                    <li><a target='_blank' rel="noreferrer" href='/'><img src={linkned} alt=''></img></a></li>
                    <li><a target='_blank' rel="noreferrer" href='https://www.instagram.com/shop.hoarder/'><img src={instagram} alt=''></img></a></li>
                    <li><a target='_blank' rel="noreferrer" href='https://www.tiktok.com/@shop.hoarder'><img className='w-[18px]' src={tiktok} alt=''></img></a></li>
                </ul>
            </div>
            <div className='border-t mt-11 h-[0.4px] max-sm:mx-[8%]'>
            </div>
            <div className='mt-[6%] text-white max-lg:mt-[10%] text-center max-sm:w-full' id='font-roboto'>
                <p className='text-sm'>© 2023 Hoarder Marketplace Limited. All rights reserved</p>
            </div>
        </div> 
    </div>
    
  )
}

export default FooteR