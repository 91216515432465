import React, { useEffect } from 'react'
import frame from "../Images/Frame 2.png";
import kite from "../Images/Group 2027.png";
import 'aos/dist/aos.css';
import Aos from 'aos';

const JoinCommunityPage = () => {
    useEffect(() =>{
        Aos.init({once: true});
    },[])
  return (
    <div className='w-full px-[6%] mt-[10%] flex justify-between max-lg:flex-col max-lg:pt-[22%] jcommunity'data-aos="fade-down">
        <div className='w-[33%] max-lg:w-full'>
            <h1 className='text-[30px] font-extrabold' id='font-gliker'>
                Join a<span className='text-[#872794] ml-2'>Community</span> of Thrift lovers ❤️ Bargain hunters
            </h1>
        </div>
        <div className='w-[35%] max-lg:w-full max-lg:mt-7'>
            <img src={frame} alt=''></img>
        </div>
        <div className='w-[20%] max-lg:w-full max-lg:mt-7'>
            <img src={kite} alt=''></img>
        </div>
    </div>
  )
}

export default JoinCommunityPage