import React, { useEffect } from 'react'
import 'aos/dist/aos.css';
import Aos from 'aos';

const ContactUs = () => {
    useEffect(() =>{
        Aos.init({once: true});
    },[])
  return (
    <div className='w-full grid place-items-center h-[75vh] items-center pt-[6%] mb-[15%] max-md:px-[5%] max-sm:pt-[30%] max-sm:mb-[14%] max-md:justify-start max-md:items-start' data-aos="zoom-in" id='contactus'>
        {/* 1 */}
        <div className='w-[25%] max-sm:w-full text-center grid justify-center max-md:text-start'>
            <h1 className='text-lg' id='font-gliker'>Get Early Access</h1>
            <p className='mt-3 w-full'>Be one of the first to start decluttering and
            start shopping. Limited spaces available, request access now </p>
        </div>
        <div className='mt-4 max-sm:mt-0 max-md:w-full'>
            <form>
                <div className='w-full mt-3'>
                    <label>Fullname</label>
                    <input type='text' className='w-full outline-none px-2 py-3 mt-2 border border-[#872794] rounded-lg' required></input>
                </div>
                <div className='w-full mt-3'>
                    <label>Email</label>
                    <input type='email' className='w-full outline-none px-2 py-3 mt-2 border border-[#872794] rounded-lg' required></input>
                </div>
                <div className='w-full mt-3'>
                    <label>Phone Number</label>
                    <input type='number' className='w-full outline-none px-2 py-3 mt-2 border border-[#872794] rounded-lg' min="0" max="100" required></input>
                </div>
                <div className='w-full mt-4'>
                    <input type='submit' className='w-full flex justify-center items-center border-2 py-3 border-transparent bg-[#872794] rounded-lg text-white' value='Join Waitlist'></input>
                </div>
            </form>
        </div>
        {/* 2 */}
    </div>
  )
}

export default ContactUs