import React, { useEffect } from 'react'
import googleplay from '../Images/Google Play.png';
import appstore from '../Images/app store.png';
import 'aos/dist/aos.css';
import Aos from 'aos';
const PlayAppStore = () => {
    useEffect(() =>{
        Aos.init({once: true});
    },[])
  return (
    <div className='max-sm:mt-[1%] mt-[20%] grid place-items-center'data-aos="zoom-in" >
        <div className='max-md:w-full break-word w-full '>
            <h1 className='text-lg max-sm:text-[26px] font-extrabold w-full text-center whitespace-break-spaces break-word'id='font-gliker'>
                Coming soon on<br></br> App store and google play
            </h1>
        </div>
        <div className='w-[20%] flex items-center justify-around mt-4 max-md:flex-col max-md:justify-center max-md:w-full'>
            <div className=' w-[50%] max-md:w-full'>
                <div className='w-full flex items-center max-md:w-full'>
                    <button><img className='max-md:mx-auto max-md:block max-md:w-[40%]' src={appstore} alt=''></img></button>
                </div> 
            </div>
            <div className=' w-[50%] ml-2 max-md:mt-2 max-md:w-full'>
                <div className='w-full flex items-center max-md:w-full'>
                    <button><img  className='max-md:mx-auto max-md:block max-md:w-[40%]'src={googleplay} alt=''></img></button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PlayAppStore