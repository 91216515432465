import React, { useEffect, useRef, useState } from 'react'
import phone2 from '../Images/iPhone 12 Pro Mockup Front View.svg'
import phonefront from "../Images/iPhone 12 Pro Mockup Front View a.png";
import 'aos/dist/aos.css';
import Aos from 'aos';

const SeamlessSwitchPage = () => {
    useEffect(() =>{
        Aos.init({once: true});
    },[])

    const scrollRef = useRef(null);

    const [move, setMove] = useState(false)
    const handleToogle = () =>{
        setMove(!move);
        scrollRef.current.scrollTop = 0;
    }
  return (
    <div className='px-[6%] pt-[10%] max-lg:flex max-lg:flex-col max-lg:pt-[24%] h-[50vh] max-sm:pt-[5%] mb-[36%] max-sm:mb-[53rem] max-sm:h-[100vh]' ref={scrollRef} id="whatweoffer" data-aos='zoom-in'>
        {/* 1 */}
        <div className='flex justify-between items-center max-lg:flex-col' >
            <div className='w-[40%] max-lg:w-full'>
                <h2 className='text-[#972794] font-bold text-md max-lg:hidden'id='font-gliker'>
                    Seamlessly Switch 
                    Between  Buying or Seller 
                </h2>
                <h2 className='font-bold text-md lg:hidden'id='font-gliker'>
                    Seamlessly Switch 
                    Between  Buying or Seller 
                </h2>
            </div>
            <div className='w-[50%] flex items-center max-lg:mt-5 max-lg:w-full max-lg:justify-between'>
                <span className='w-[30%] max-lg:w-[50%]'>
                    <h3 className={`text-[20px] font-semibold ${move ? 'text-[#545454]' : 'text-[#5E3482]'}`}>Hoarder Buyer</h3>
                </span>
                <span className='w-[30%] max-lg:w-[30%]'>
                    <nav className='w-[121px] py-1 pl-2 pr-0 border-2 bg-[#D9D9D9] rounded-full flex items-center max-lg:py-1 max-sm:w-[90px]'>
                      <button className={`border-2 bg-[#5E3482] p-5 max-sm:p-3 rounded-full transition duration-100 ease-in-out ${ move ? 'border-2 bg-[#A074C6] p-5 max-sm:p-3 rounded-full transition duration-100 ease-in-out transform translate-x-[137%]':''}`} onClick={handleToogle}></button> 
                    </nav>
                </span>
                <span className='w-[30%] max-lg:hidden'>
                    <h3 className={`text-[20px] font-semibold ${move ? 'text-[#5E3482]' : 'text-[#545454]'}`}>Hoarder Seller</h3>
                </span>
            </div>
        </div>
        {/* 2 */}
        <div className='w-full flex justify-between mt-[3%] max-lg:flex-col seemless'>
            <div className={move ? 'w-[36%] relative flex justify-between h-[58vh] bg-[#A074C6] rounded-lg max-lg:w-full max-lg:mt-4 seemless_boarder transition duration-150' : 'w-[36%] relative flex justify-between h-[58vh] bg-[#5E3482] rounded-lg max-lg:w-full max-lg:mt-4 seemless_boarder transition duration-150'}>
                 <div className='h-full flex flex-col justify-center px-7 max-sm:w-[60%]'>
                    <h2 className='text-white text-md max-sm:text-[24px] w-[30%]' id='font-gliker'>
                        {move ? 'Upload Items' : 'Discover'}
                    </h2>
                    <p className='text-white w-[45%] max-sm:text-sm' id='font-livvic'>
                        {move ? 'Upload those items you are never going to wear again and let buyers find you' : 'Discover new and preloved items at  affordable prices'}
                    </p>
                </div>
                <div className='w-[50%] absolute bottom-0 right-0 max-lg:w-[50%] max-sm:w-[60%]'>
                    <div className='w-full h-auto max-lg:hidden'>
                        <img className='w-full h-full' src={phone2} alt=''></img>
                    </div>
                    <div className='w-full h-auto lg:hidden'>
                        <img className='w-full h-auto' src={phonefront} alt=''></img>
                    </div>
                </div>
            </div>
            <div className={move ? 'w-[36%] relative flex justify-between h-[58vh] bg-[#A074C6] rounded-lg ml-2 max-lg:w-full max-lg:ml-0 max-lg:mt-4 seemless_boardertransition duration-150' : 'w-[36%] relative flex justify-between h-[58vh] bg-[#5E3482] rounded-lg ml-2 max-lg:w-full max-lg:ml-0 max-lg:mt-4 seemless_boardertransition duration-150'}>
                <div className='h-full flex flex-col justify-center px-7 max-sm:w-[60%]'>
                    <h2 className='text-md text-white max-sm:text-[24px] w-[50%]' id='font-gliker'>
                    {move ? 'Get ready' : 'Secure Payment'}
                    </h2>
                    <p className='text-white max-sm:text-sm w-[45%]' id='font-livvic'>
                        {move ? 'Get your order ready for its new home' : 'Checkout with a  smile on your face  and your mind at  ease'}
                    </p>
                </div>
                <div className='w-[50%] absolute bottom-0 right-0 max-lg:w-[50%] max-sm:w-[60%]'>
                    <div className='w-full h-auto max-lg:hidden'>
                        <img className='w-full h-auto' src={phone2} alt=''></img>
                    </div>
                    <div className='w-full h-auto lg:hidden'>
                        <img className='w-full h-auto' src={phonefront} alt=''></img>
                    </div>
                </div>
            </div>
            <div className={move ? 'w-[36%] relative flex justify-between h-[58vh] bg-[#A074C6] rounded-lg ml-2 max-lg:w-full max-lg:ml-0 max-lg:mt-4 seemless_boarder transition duration-150' : 'w-[36%] relative flex justify-between h-[58vh] bg-[#5E3482] rounded-lg ml-2 max-lg:w-full max-lg:ml-0 max-lg:mt-4 seemless_boarder transition duration-150'}>
                <div className='h-full flex flex-col justify-center px-7 max-sm:w-[60%]'>
                    <h2 className='text-white text-md max-sm:text-[24px]  w-[50%]' id='font-gliker'>
                       {move ? 'Get Paid' : 'Accept  order'}
                    </h2>
                    <p className='text-white max-sm:text-sm w-[50%]' id='font-livvic'>
                        {move ? 'Sit back and relax while Hoarder handles all the logistics involved and you get paid' : 'Receive your  Hoarder package  within reasonable  time, accept the  item and enjoy'}
                    </p>
                </div>
                <div className='w-[50%] absolute bottom-0 right-0 max-lg:w-[50%] max-sm:w-[60%]'>
                    <div className='w-full h-auto max-lg:hidden'>
                        <img className='w-full h-auto' src={phone2} alt=''></img>
                    </div>
                    <div className='w-full h-auto lg:hidden'>
                        <img className='w-full h-auto' src={phonefront} alt=''></img>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SeamlessSwitchPage