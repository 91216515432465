import React, { useEffect } from 'react'
import phone from "../Images/iPhone 12 Pro Mockup Front View.png";
import moneybag from "../Images/Money Bag.svg";
import bagsmile from "../Images/Bag Smile.svg";
import smile from "../Images/smile.svg";
import profilepic from "../Images/Group 2067.png";
import bday from "../Images/bday.svg";
import heart from "../Images/heart.svg";
import iPhonemobile from "../Images/iPhone 12 Pro Mockup Front View mobile.png"; 
import {HashLink as Link} from "react-router-hash-link"
import 'aos/dist/aos.css';
import Aos from 'aos';


const HomeCo = () => {
    useEffect(() =>{
        Aos.init({once: true});
    },[])
  return (
    <div className='px-[6%] flex justify-between pr-[8%] pt-[10%] max-sm:pt-[18%] max-lg:flex-col'>
        <div className='pt-[8%] w-[50%] max-lg:w-full max-lg:mt-[10%]'>
            <div>
                <h1 className='text-xl font-black leading-none max-lg:hidden'id='font-gliker'>
                    Your one stop shop  for new and preloved  clothes, shoes and  accessories
                </h1>
                <h1 className='text-md font-black leading-tight lg:hidden'id='font-gliker'>
                    Your one stop  shop for new  and preloved clothes, shoes  and accessories
                </h1>
            </div>
            <div className='mt-3 w-[60%] max-sm:w-full' id='font-livvic'>
                <p className='max-lg:hidden'>
                    Declutter your wardrobe and discover new and preloved pieces at affordable prices.
                </p>
                <p className='lg:hidden mt-4'>
                    Declutter your wardrobe and discover  new and preloved pieces at affordable  prices.
                </p>
            </div>
            <div className='mt-4'>
             <Link to="#contactus" smooth duration={2000} offset={-75}> <button className=' px-[7%] py-[2%] flex justify-center items-center border bg-[#872794] rounded-lg text-white text-sm max-lg:py-[4%]' id='font-inter'>Get early access</button></Link>
            </div>
      </div>
      <div className='flex justify-between w-[45%] pt-[5%] max-lg:w-full max-lg:flex-col max-lg:mt-4'>
        <div className='w-[60%] max-lg:w-full'>
            <div className='w-full'>
                <img className='max-lg:hidden' src={phone} alt=''></img>
                <img className='lg:hidden' src={iPhonemobile} alt=''></img>
            </div>
        </div>
        <div className='w-[80%] flex flex-col justify-center pl-4 max-lg:grid max-lg:w-full max-lg:mt-4'id='font-inter'>
            {/* 1 */}
            <div className='flex w-full items-center justify-between max-lg:hidden'>
                <div className='w-[39px]'>
                    <div className='w-full rounded-full bg-white flex items-center justify-center p-2 '>
                        <img src={bagsmile} alt=''></img>
                    </div>
                </div>
                <div className=' w-[85%] ml-2'>
                   <div className='border-2 bg-white rounded-full w-[141px] px-4 py-3 text-[#3B019B] font-semibold'>
                        <p className='text-sm'>₦12,000 sale!!</p>
                    </div> 
                </div>
            </div>
            {/* 2 */}
            <div className='flex w-full items-center justify-between mt-4 max-lg:hidden'>
                <div className='w-[39px]'>
                    <div className='w-full rounded-full bg-white flex items-center justify-center p-2 '>
                        <img src={smile} alt=''></img>
                    </div>
                </div>
                <div className=' w-[85%] ml-2'>
                   <div className=' bg-[#872794] rounded-full text-white w-[192px] px-4 py-3'>
                        <p className='text-sm'>300 sales in this month!</p>
                    </div> 
                </div>
            </div>
            {/* 3 */}
            <div className='flex w-full items-center justify-between mt-4 max-lg:hidden'>
                <div className='w-[39px]'>
                    <div className='w-full rounded-full'>
                        <img src={profilepic} alt=''></img>
                    </div>
                </div>
                <div className=' w-[85%] ml-2'>
                   <div className='border-2 bg-white rounded-full w-[240px] px-4 py-3'>
                        <span className='flex'>
                            <p className='text-sm'>Package Recieved! Love it</p>
                            <img className='ml-2' src={heart} alt=''></img>
                        </span>
                    </div> 
                </div>
            </div>
            {/* 4 */}
            <div className='flex w-full items-center justify-between mt-4 max-lg:hidden'>
                <div className='w-[39px]'>
                    <div className='w-full rounded-full bg-white flex items-center justify-center p-2'>
                        <img src={moneybag} alt=''></img>
                    </div>
                </div>
                <div className=' w-[85%] ml-2'>
                   <div className='border-2 bg-white rounded-full w-[200px] px-4 py-3'>
                        <span className='flex'>
                            <p className='text-sm'>Payment Received</p>
                            <img className='ml-2' src={bday} alt=''></img>
                        </span>
                    </div> 
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default HomeCo