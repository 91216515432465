import React from 'react'
// import NavBar from './NavBar'
import HomeCo from './HomeCo'
// import grade from "../Images/Gradient.png";

const Home = () => {
  return (
    <div className=' h-[100vh] bg-[url("Images/Gradient.svg")] bg-center bg-no-repeat bg-cover' id='home' >
      {/* <NavBar/> */}
      {/* Home */}
      <HomeCo/>
    </div>
  )
}

export default Home 