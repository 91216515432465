import React, { useEffect, useState } from 'react'
import logo from "../Images/Logo.svg"
import menu from "../Images/Group 2036.svg";
import {HashLink as Links} from "react-router-hash-link"


const NavBar = ({onScrollToContactUS}) => {
  const [isSticky , setIsSticky] = useState(false)
  const [isOpen , setIsOpen] = useState(false)

  const Toggle = () =>{
    setIsOpen(!isOpen)
  } 

  useEffect(() =>{
    const handleScroll = () => {
      const offset = 20
      const pastOffset = window.scrollY > offset
      setIsSticky(pastOffset)
      console.log(window.scrollY)
    };
    window.addEventListener('scroll',handleScroll)
    return () => {
      window.removeEventListener('scroll',handleScroll)
    }
  },[])
  return (
    <div className={`${isSticky ? 'sticky top-0 w-full flex justify-between px-[6%] py-[1%] max-lg:py-[2%] bg-white z-40 shadow-md transition ease-linear duration-500' :'absolute top-0 w-full bg-transparent px-[6%] py-[2%] mt-4 flex justify-between transition ease-linear duration-100'}`}>
      <div className=''>
        <div className='flex items-center py-2'>
          <a href='/'><img src={logo} alt=''></img></a>
        </div>
      </div>
      <div className='w-[50%] max-lg:w-[60%] max-lg:flex max-lg:justify-between max-lg:items-center navbarwidth'>
        <div className='w-full'>
          <ul className='flex w-full justify-around items-center max-lg:justify-between '>
            <li className='max-lg:hidden'>
              <Links to='#home' smooth duration={2000} offset={-100}>
                <span className='text-sm'>Home</span>
              </Links>
              </li>
            <li className='max-lg:hidden'>
                <Links to="#whatweoffer" smooth duration={2000} offset={-50}>
                  <span className='text-sm'>What we Offer</span>
                </Links>
              </li>
            <li className='max-lg:hidden'>
                <Links to="#faq" smooth duration={2000} offset={-80}>
                  <button className='text-sm'>FAQ</button>
                </Links>
              </li>
            <li>
              <Links to="#contactus" smooth duration={2000} offset={-75}>
              <button className='w-[141px] max-lg:w-[161px] px-[7%] py-[10%] flex justify-center items-center border bg-[#872794] rounded-lg text-white text-sm max-lg:hidden' id='font-inter'>Get early access</button>
            </Links>
            </li>
          </ul>
        </div>
        <div className='lg:hidden'>
          <button onClick={Toggle}><img src={menu} alt=''></img></button>
        </div>
      </div>
      { isOpen && (
          <div className='absolute left-0 top-16 w-full pt-[3rem] pb-[35rem] h-[100vh] px-0 bg-white grid justify-center item-center z-20 lg:hidden'>
            <ul className='flex flex-col w-full justify-between items-center max-lg:justify-between text-center '>
              <li className=''>
                <Links to='#home' smooth duration={2000} offset={-100}>
                <span className='text-sm'>Home</span>
              </Links></li>
              <li className=''>
                <Links to="#whatweoffer" smooth duration={2000} offset={-50}>
                  <span className='text-sm'>What we Offer</span>
                </Links></li>
              <li className=''>
                <Links to="#faq" smooth duration={2000} offset={-80}>
                  <button className='text-sm'>FAQ</button>
                </Links></li>
              <li>
                <Links to="#contactus" smooth duration={2000} offset={-75} >
                <button className='w-[141px] max-lg:w-[161px] px-[7%] py-[10%] flex justify-center items-center border bg-[#872794] rounded-lg text-white text-sm' id='font-inter'>Get early access</button>
              </Links>
              </li>
            </ul>
          </div>
        )}
    </div>
  )
}

export default NavBar