import React, { useEffect } from 'react'
import phone3 from '../Images/Group 2069.png';
import 'aos/dist/aos.css';
import Aos from 'aos';

const FaqPage = () => {
    useEffect(() =>{
        Aos.init({once: true});
    },[])
  return (
  <div className='flex flex-col w-full pt-[10%] mb-[27%] max-sm:mb-[2%] bg-[url("Images/Group 2071.png")] bg-center bg-no-repeat bg-cover h-[80vh]' id='faq' data-aos="zoom-in">
    <div>
        <h1 className='text-[32px] text-[#872794] font-extrabold text-center' id='font-gliker'>FAQs</h1>
    </div>
    <div className='px-[5%] mt-[5%] flex justify-between'>
        <div className='w-[50%] h-[40vh] max-md:hidden'>
            <div className='w-[93%] h-auto'>
                <img className='' src={phone3} alt=''></img>
            </div>
        </div>
        <div className='w-[70%] flex flex-col max-md:w-full'>
            <details open className='border-t [&_svg]:open:-rotate-180 [&_svg]:cursor-pointer py-4'>
                <summary className='flex justify-between items-center py-1'>
                    <button className='cursor-text'><h2 className='text-[26px] max-sm:text-[22px] font-bold max-sm:font-normal text-start' id='font-gliker'>What is hoader</h2></button><svg width="24" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.384204 1.18653C0.833482 0.662376 1.62261 0.601674 2.14676 1.05095L12.9999 10.3537L23.8531 1.05095C24.3773 0.601675 25.1664 0.662377 25.6157 1.18653C26.065 1.71069 26.0043 2.49982 25.4801 2.94909L13.8134 12.9491C13.3453 13.3503 12.6546 13.3503 12.1865 12.9491L0.519785 2.94909C-0.00437194 2.49982 -0.0650738 1.71069 0.384204 1.18653Z" fill="#1C274C"/></svg></summary>
                <p className='break-words w-[92%] max-sm:text-[15px]'>simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
            </details>
            <details className='border-t [&_svg]:open:-rotate-180 [&_svg]:cursor-pointer py-4'>
                <summary className='list-none flex justify-between items-center py-1'>
                    <button className='cursor-text'><h2 className='text-[26px] max-sm:text-[22px] font-bold max-sm:font-normal text-start' id='font-gliker'>How does it work</h2></button><svg width="24" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.384204 1.18653C0.833482 0.662376 1.62261 0.601674 2.14676 1.05095L12.9999 10.3537L23.8531 1.05095C24.3773 0.601675 25.1664 0.662377 25.6157 1.18653C26.065 1.71069 26.0043 2.49982 25.4801 2.94909L13.8134 12.9491C13.3453 13.3503 12.6546 13.3503 12.1865 12.9491L0.519785 2.94909C-0.00437194 2.49982 -0.0650738 1.71069 0.384204 1.18653Z" fill="#1C274C"/></svg></summary>
                <p className='break-words w-[92%] max-sm:text-[15px]'>It’s really simple.As a Seller, create an account, upload items you want to sell, let buyers find you and Hoarder will handle the logistics while you get paid.
                As a Buyer, create an account, explore, add to your cart and checkout while Hoarder works on getting your order to you asap!</p>
            </details>
            <details className='border-t [&_svg]:open:-rotate-180 [&_svg]:cursor-pointer py-4'>
                <summary className='list-none flex justify-between items-center py-1'>
                    <button className='cursor-text'><h2 className='text-[26px] max-sm:text-[22px] font-bold max-sm:font-normal text-start' id='font-gliker'>What can i sell on Hoader</h2></button> <svg width="24" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.384204 1.18653C0.833482 0.662376 1.62261 0.601674 2.14676 1.05095L12.9999 10.3537L23.8531 1.05095C24.3773 0.601675 25.1664 0.662377 25.6157 1.18653C26.065 1.71069 26.0043 2.49982 25.4801 2.94909L13.8134 12.9491C13.3453 13.3503 12.6546 13.3503 12.1865 12.9491L0.519785 2.94909C-0.00437194 2.49982 -0.0650738 1.71069 0.384204 1.18653Z" fill="#1C274C"/></svg></summary>
                <p className='break-words w-[92%] max-sm:text-[15px]'>
                    Good question!
                
                You can sell both new and preloved clothes, shoes, accessories and more. We are open to seeing what you want to sell.
                </p>
            </details>
            <details className='border-t [&_svg]:open:-rotate-180 [&_svg]:cursor-pointer py-4'>
                <summary className='list-none flex justify-between items-center py-1'>
                    <button className='cursor-text'><h2 className='text-[26px] max-sm:text-[22px] font-bold max-sm:font-normal text-start' id='font-gliker'>Can I sell new clothes</h2></button> <svg width="24" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.384204 1.18653C0.833482 0.662376 1.62261 0.601674 2.14676 1.05095L12.9999 10.3537L23.8531 1.05095C24.3773 0.601675 25.1664 0.662377 25.6157 1.18653C26.065 1.71069 26.0043 2.49982 25.4801 2.94909L13.8134 12.9491C13.3453 13.3503 12.6546 13.3503 12.1865 12.9491L0.519785 2.94909C-0.00437194 2.49982 -0.0650738 1.71069 0.384204 1.18653Z" fill="#1C274C"/></svg></summary>
                <p className='break-words w-[92%] max-sm:text-[15px]'>
                    Yes you can!
                    Hoarder welcomes both new and preloved items
                </p>
            </details>
            <details className='border-t [&_svg]:open:-rotate-180 [&_svg]:cursor-pointer py-4'>
                <summary className='list-none flex justify-between items-center py-1'>
                    <button className='cursor-text'><h2 className='text-[26px] max-sm:text-[22px] font-bold max-sm:font-normal text-start break-words' id='font-gliker'>How long will it take to receive my item?</h2></button> <svg width="24" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.384204 1.18653C0.833482 0.662376 1.62261 0.601674 2.14676 1.05095L12.9999 10.3537L23.8531 1.05095C24.3773 0.601675 25.1664 0.662377 25.6157 1.18653C26.065 1.71069 26.0043 2.49982 25.4801 2.94909L13.8134 12.9491C13.3453 13.3503 12.6546 13.3503 12.1865 12.9491L0.519785 2.94909C-0.00437194 2.49982 -0.0650738 1.71069 0.384204 1.18653Z" fill="#1C274C"/></svg></summary>
                <p className='break-words w-[92%] max-sm:text-[15px]'>Once your payment is confirmed, we will start processing your order. Your order timeline depends on where the seller is located and where you are located. Click here to see our shipment policy and guide.</p>
            </details>
            <details className='border-t [&_svg]:open:-rotate-180 [&_svg]:cursor-pointer py-4'>
                <summary className='list-none flex justify-between items-center py-1'>
                    <button className='cursor-text'><h2 className='text-[26px] max-sm:text-[22px] font-bold max-sm:font-normal text-start break-words' id='font-gliker'>How much does it cost to sell?</h2></button> <svg width="24" height="14" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.384204 1.18653C0.833482 0.662376 1.62261 0.601674 2.14676 1.05095L12.9999 10.3537L23.8531 1.05095C24.3773 0.601675 25.1664 0.662377 25.6157 1.18653C26.065 1.71069 26.0043 2.49982 25.4801 2.94909L13.8134 12.9491C13.3453 13.3503 12.6546 13.3503 12.1865 12.9491L0.519785 2.94909C-0.00437194 2.49982 -0.0650738 1.71069 0.384204 1.18653Z" fill="#1C274C"/></svg></summary>
                <p className='break-words w-[92%] max-sm:text-[15px]'>Hoarder charges a flat fee of 12% of the selling price of all items listed. What this means is that if you upload a top for N1,000 on Hoarder, when the top sells, Hoarder will charge you N120 and you keep the rest which would be N880.</p>
            </details>
        </div>
    </div>
  </div>
    
  )
}

export default FaqPage