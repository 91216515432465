import React from "react";
import LandingPage from "./components/LandingPage";
import {BrowserRouter as Router,Routes,Route} from "react-router-dom"
import FooteR from "./components/FooteR";
import NavBar from "./components/NavBar";
// import ContactUs from "./components/ContactUs";
// import NavBar from "./components/NavBar";

function App() {
  return (
    <Router>
      <NavBar/>
      <Routes>
        <Route index element={<LandingPage />} />
      </Routes>
      <FooteR/>
    </Router>
  );
}

export default App;
