import React, { useEffect } from 'react'
import community from '../Images/Coomunity.png'
import {HashLink as Link } from 'react-router-hash-link'
import 'aos/dist/aos.css';
import Aos from 'aos';
const Testimonials = () => {
  useEffect(() =>{
    Aos.init({once: true});
},[])
  return (
    <div className='mt-[10%]'>
        {/* 1 */}
        <div className='w-full px-[8%] max-sm:pt-[22%] flex justify-between items-center max-md:flex max-md:flex-col max-md:items-start'>
            <div>
                <h2 className='text-[#972794] font-bold text-lg w-[64%] max-md:hidden'data-aos="fade-up" id='font-gliker'>
                    Dont just take our word for it.  Here is what the community has to day
                </h2>
                <h2 className='text-[#972794] font-bold text-[28px] lg:hidden' id='font-gliker'>
                    Dont just take our word  for it.  Here is what the  community has to day
                </h2>
            </div>
            <div className='mt-5' data-aos="fade-up">
              <Link to="#contactus" smooth duration={2000} offset={-75}><button className='w-[161px] px-[9%] py-[10%] flex justify-center items-center border bg-[#872794] rounded-lg text-white text-sm' id='font-inter'>Get early access</button></Link>
            </div>
        </div>
        {/* 2 */}
        <div className='mt-6' data-aos="fade-down">
            <img src={community} alt=''></img>
        </div>
    </div>
  )
}

export default Testimonials