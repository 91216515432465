import React from 'react'
import Home from './Home'
import JoinCommunityPage from './JoinCommunityPage'
import SeamlessSwitchPage from './SeamlessSwitchPage'
import Testimonials from './Testimonials'
import FaqPage from './FaqPage'
import ContactUs from './ContactUs'
import PlayAppStore from './PlayAppStore'

const LandingPage = () => {
  return (
    <div className='w-[100%]'>
        <Home/>
        <JoinCommunityPage/>
        <SeamlessSwitchPage/>
        <Testimonials/> 
        <FaqPage/>
        <ContactUs/>
        <PlayAppStore/>
    </div>
  )
}

export default LandingPage